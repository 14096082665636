import { Step, StepDescription, StepNumber, StepsSection } from "../reusable.styles"

import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import styled from 'styled-components';

const RemoteEmployees = ({ location }) => {

  const valueProps = [
    {
      headline:"Boost employee morale",
      subheadline:"Motivate remote employees by maintaining work routines like company-provided lunch.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/G-RemoteEmployeeMeals/EXPORTS-Illustrations-RemoteMeals-Icon1-HouseRocket-Citrus.png",
    },
    {
      headline:"Offer remote flexibility",
      subheadline:"Provide food options to remote employees, so they can order whatever they’re craving.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235439/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Remote%20Employees/EXPORT-RemoteEmployees-ValueProp2.png",
    },
    {
      headline:"Attract top talent",
      subheadline:"Recruit new hires by offering world-class meal perks whether they’re remote or in-office.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001198/Grubhub%20Corporate%20Website/2021/Illustrations/G-RemoteEmployeeMeals/EXPORTS-Illustrations-RemoteMeals-Icon3-Trophy.png",
    },
  ]

  const cards = [
    {
      title: "Digital Events",
      description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
      link: "/meals-for-digital-events/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-DigitalEvents.jpg",
      linkText: "Learn more"
    },
    {
      title: "Corporate Perks",
      description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
      link: "/perks/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
      linkText: "Learn more"
    },
    {
      title: "Hybrid Teams",
      description: "Keep your hungry teams happy, whether they're in the office, at home or both.",
      link:"/hybrid-teams/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "How do you order food delivery for work from home employees?",
      description: "<span>A Grubhub Corporate Account makes it easy for you to <a href='/meals-for-remote-employees/'>feed your remote employees</a>. Simply provide a meal credit and let employees choose from over 300,000 restaurants in over 4,000 U.S. cities.</span>"
    },
    {
      header: "How do you host a virtual lunch meeting?",
      description: "<span>A <a href='/'>Grubhub Corporate Account</a> makes it easy to host a virtual lunch meeting. The first thing you should do is set your budget and then enable <a href='/individual-meals/'>individual meal ordering</a> which allows attendees to independently order meals based on the budget you provided. And by partnering with Grubhub, you'll only pay for what's ordered!</span>"
    }
  ]

  return (
    <Layout
      location={location}
      title={"Feed Remote Employees | Employee Perks | Grubhub Corporate"}
      description={"Show your remote team you care by offering meal perks. A Grubhub Corporate Account makes it easy for you to feed your remote employees."}
      className="remote-employees-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "remote employees",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Remote Employees"
        subheadline="We'll help you deliver food and happiness to your team, no matter where they're working."
        textColor="var(--color-secondary)"
        background="#E7F4EE"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796710/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-RemoteEmployees.jpg"
        alt="Woman smiling eating a salad while looking at her laptop screen."
      />
      <ValuePropsSection
        headline="Support your team while they work from home"
        valueProps={valueProps}
        layout="vertical"
      />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageAlignment="right"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235439/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Remote%20Employees/CircularImage-RemoteEmployees.png"
        alt="Grubhub delivery driver smiling handing a bag of food to a woman at her front door."
        headline="How it works"
        backgroundColor="rgba(231,244,238,0.4)"
      >
        <StepsSection>
          <Step>
            <RestyledStepNumber>Step 1</RestyledStepNumber>
            <StepDescription>Set a budget—recurring or one-time</StepDescription>
          </Step>
          <Step>
            <RestyledStepNumber>Step 2</RestyledStepNumber>
            <StepDescription>Tell your team about their meal credits! </StepDescription>
          </Step>
          <Step>
            <RestyledStepNumber>Step 3</RestyledStepNumber>
            <StepDescription>Employees order delivery directly to their homes</StepDescription>
          </Step>
        </StepsSection>
      </TwoCol_ImageContentSection>
      <QuoteSection
        quote='"Grubhub has helped me feed all 2,000 of my remote employees. I’m not sure how I survived before the Corporate Grubhub Account."'
        author="Nea H"
        position="Director of People at Asurion"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Asurion.png"
        alt="Asurion logo"
        backgroundColor="white"
      />
      <CardsSection backgroundColor="rgba(231,244,238,0.4)" headline="More ordering occasions and information" cards={cards} chunkCards isSliderOnMobile cardsPerRow={3}/>
      <CtaSection headline="See what we can do for you" backgroundColor="white"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="#F9F9F9"/>
    </Layout>
  )
}

export default RemoteEmployees

const RestyledStepNumber = styled(StepNumber)`
  color:#108F57;
`;
